import './App.css';
import Lottie from "lottie-react";
import Svg404 from './duck.json';
import { Box, Button, Typography } from "@mui/material";
import LinkIcon from '@mui/icons-material/Link';

export default function App() {

    return (
        <div className="App">
            <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", paddingTop:5 }}>
                <Typography component={"h1"} variant='h2' sx={{fontSize:{xxs:"34px",md:'54px'}, textAlign:'center'}}>Ouverture du journal de Dey-dey</Typography>
                <Box sx={{display:'flex',flexDirection:{xxs:'column',xs:"row"},fontSize:"14px",textAlign:"center"}}>
                    <li><span id="days"></span>Jours</li>
                    <li><span id="hours"></span>Heures</li>
                    <li><span id="minutes"></span>Minutes</li>
                </Box>
                <Lottie style={{ height: "312px" }} animationData={Svg404} />
                <Button target="_blank" href="https://linktr.ee/deydey_adventures" sx={{ fontWeight: "600", fontSize: {xxs:20,xs:28} }} startIcon={<LinkIcon />} variant="outlined"> Mes réseaux</Button>
            </Box>
        </div>
    );
}